export const POINT_SUCCESS = {
  APPROVED: 1,
  PENDING: 2,
  CANCELLED: 3,
  FAILED: 4,
  ADJUSTED: 5,
  EXPIRED: 6,
};

export const POINT_SUCCESS_LABEL = {
  [POINT_SUCCESS.APPROVED]: 'Approved',
  [POINT_SUCCESS.PENDING]: 'Pending',
  [POINT_SUCCESS.CANCELLED]: 'Declined',
  [POINT_SUCCESS.FAILED]: 'Failed',
  [POINT_SUCCESS.ADJUSTED]: 'Adjusted',
  [POINT_SUCCESS.EXPIRED]: 'Expired',
};

export const POINT_SUCCESS_COLOR = {
  [POINT_SUCCESS.APPROVED]: 'success',
  [POINT_SUCCESS.PENDING]: 'warning',
  [POINT_SUCCESS.CANCELLED]: 'default',
  [POINT_SUCCESS.FAILED]: 'error',
  [POINT_SUCCESS.ADJUSTED]: 'primary',
  [POINT_SUCCESS.EXPIRED]: 'secondary',
};

export const MEMBERSHIP_TICKET_STATUS = {
  ACTIVE: 1,
  USED: 2,
  USED_MANUAL: 3,
};

export const MEMBERSHIP_TICKET_LABEL = {
  [MEMBERSHIP_TICKET_STATUS.ACTIVE]: 'Not used yet',
  [MEMBERSHIP_TICKET_STATUS.USED]: 'Used at IP',
  [MEMBERSHIP_TICKET_STATUS.USED_MANUAL]: 'Used manually',
};

export const MEMBERSHIP_TICKET_COLOR = {
  [MEMBERSHIP_TICKET_STATUS.ACTIVE]: 'success',
  [MEMBERSHIP_TICKET_STATUS.USED]: 'error',
  [MEMBERSHIP_TICKET_STATUS.USED_MANUAL]: 'error',
};

export const VOUCHER_TXN_MODAL_INFOMATIONS = {
  ['getvoucher']: {
    title: '‘Get voucher’ platform',
    description:
      '<b>Web / App</b> – Members get voucher on web or app' +
      '<br/>' +
      '<b>Unspecified</b> – Members get voucher prior to 25 Aug 2023, when platform information is not available' +
      '<br/>' +
      '<b>System</b> – Voucher distributed by our system',
  },
  ['usevoucher']: {
    title: '‘Use voucher’ platform',
    description:
      '<b>Web / App</b> – Members use voucher on web or app' +
      '<br/>' +
      '<b>Unspecified</b> – Members use voucher prior to 25 Aug 2023, when platform information is not available ' +
      '<br/>' +
      '<b>System</b> – Voucher removed by admin',
  },
};

export const REFERRAL_TRANSACTION_STATUS = {
  COMPLETE: 1,
  PENDING: 2,
};

export const ELEMENT_STATUS = {
  ACTIVE: 1,
  DELETED: 2,
};

export const REFERRAL_TRANSACTION_STATUS_LABEL = {
  [REFERRAL_TRANSACTION_STATUS.COMPLETE]: 'Complete',
  [REFERRAL_TRANSACTION_STATUS.PENDING]: 'Pending',
};

export const REFERRAL_TRANSACTION_STATUS_COLOR = {
  [REFERRAL_TRANSACTION_STATUS.COMPLETE]: 'success',
  [REFERRAL_TRANSACTION_STATUS.PENDING]: 'default',
};

export const VOUCHER_TRANSACTION_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
};

export const VOUCHER_TRANSACTION_STATUS_LABEL = {
  [VOUCHER_TRANSACTION_STATUS.ACTIVE]: 'Active',
  [VOUCHER_TRANSACTION_STATUS.INACTIVE]: 'Inactive',
};

export const VOUCHER_TRANSACTION_STATUS_COLOR = {
  [VOUCHER_TRANSACTION_STATUS.ACTIVE]: 'success',
  [VOUCHER_TRANSACTION_STATUS.INACTIVE]: 'default',
};

export const CTA_LINK_TYPE = {
  APP: 1,
  WEB: 2,
};
