/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {images} from '../../../../constants/image';
import {
  Modal,
  Select,
  Button,
  Input,
  RangePicker,
} from '../../../../components';
import useAuth from '../../../../hooks/useAuth';
import {envRole} from '../../../../utils';
import {USER_TYPE} from '../../../../constants/UserType';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';
import {
  REDEMPTION_PLATFORM,
  USED_PLATFORM_OPTIONS,
} from '../../../../constants/redemptionPlatform';

interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleClean?: () => void;
  handleFilter?: () => void;
  valueFilter?: any;
  valueRangePicker?: any;
  setValueFilter?: (any) => void;
  setValueRangePicker?: (any) => void;
  setOptionByStatus?: (any) => void;
  optionByStatus?: any;
  onChangeRangePicker?: (any) => void;
  onChangeUsedDateRangePicker?: (any) => void;
  islanderPartnerOptions?: any[];
}

function ModalFilter({
  visible,
  onClose,
  handleClean,
  handleFilter,
  valueFilter,
  setValueFilter,
  optionByStatus,
  onChangeRangePicker,
  onChangeUsedDateRangePicker,
  islanderPartnerOptions = [],
}: ModalFilterProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {getUser} = useAuth();
  const {merchantId} = getUser() || {};
  const role =
    +envRole === USER_TYPE.ADMINISTRATOR ? 'administrator' : 'merchant';
  const optionRedemptionPlatform = (
    Object.keys(REDEMPTION_PLATFORM) || []
  ).reduce((items, key) => {
    items.push({
      value: key,
      label: REDEMPTION_PLATFORM[key],
    });
    return items;
  }, []);

  const optionUsedPlatform = (Object.keys(USED_PLATFORM_OPTIONS) || []).reduce(
    (items, key) => {
      items.push({
        value: key,
        label: USED_PLATFORM_OPTIONS[key],
      });
      return items;
    },
    []
  );

  const onChange = (name: string, event: any) => {
    setValueFilter((prevState: any) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  const sortData = data => {
    return data.sort((a, b) => {
      if (a.label > b) return 1;
      if (a.label < b) return -1;
      return 0;
    });
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              Account ID
            </Typography>
            <Input
              type="text"
              placeholder="Enter Account ID"
              size="small"
              variant="outlined"
              value={valueFilter.snAppId_eq}
              onChange={e => onChange('snAppId_eq', e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              ‘Get voucher’ no.
            </Typography>
            <Input
              type="number"
              placeholder="Enter ‘get voucher’ no."
              size="small"
              variant="outlined"
              value={valueFilter.id_eq}
              onChange={e => onChange('id_eq', e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              Voucher title
            </Typography>
            <Input
              type="text"
              placeholder="Enter voucher title"
              size="small"
              variant="outlined"
              value={valueFilter.ticketTitle_contains}
              onChange={e => onChange('ticketTitle_contains', e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              ‘Get voucher’ date
            </Typography>
            <RangePicker
              value={valueFilter.createdDatetime}
              onChange={onChangeRangePicker}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              ‘Get voucher’ platform
            </Typography>
            <Select
              labelId="redemptionPlatform_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange('redemptionPlatform_eq', e)}
              value={valueFilter.redemptionPlatform_eq}
              placeholder="Select ‘get voucher’ platform"
              size="small"
              selectOptions={optionRedemptionPlatform}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              ‘Use voucher’ date
            </Typography>
            <RangePicker
              value={valueFilter.updatedDateTime}
              onChange={onChangeUsedDateRangePicker}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              ‘Use voucher’ platform
            </Typography>
            <Select
              labelId="usagePlatform_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange('usagePlatform_eq', e)}
              value={valueFilter.usagePlatform_eq}
              placeholder="Select ‘use voucher’ platform"
              size="small"
              selectOptions={optionUsedPlatform}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              Island Partner’s Name
            </Typography>
            <Select
              labelId="merchantId"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange('merchantId_eq', e)}
              value={valueFilter.merchantId_eq}
              placeholder="Select Island Partner's name"
              size="small"
              selectOptions={islanderPartnerOptions}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              Voucher status
            </Typography>
            <Select
              labelId="voucherStatus_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange('voucherStatus_eq', e)}
              value={valueFilter.voucherStatus_eq}
              placeholder="Select voucher status"
              size="small"
              selectOptions={optionByStatus}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button variant="text" onClick={handleClean} loading={isLoading}>
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              onClick={handleFilter}
              loading={isLoading}
              disabled={
                !valueFilter?.title_eq &&
                !valueFilter?.id_eq &&
                !valueFilter?.email_eq &&
                !valueFilter?.merchantTicketPointId_eq &&
                !valueFilter?.voucherStatus_eq &&
                !valueFilter?.snAppId_eq &&
                !valueFilter?.redemptionPlatform_eq &&
                (!valueFilter.createdDatetime[0] ||
                  !valueFilter.createdDatetime[1]) &&
                (!valueFilter.updatedDateTime[0] ||
                  !valueFilter.updatedDateTime[1]) &&
                !valueFilter?.usagePlatform_eq &&
                !valueFilter?.merchantId_eq &&
                !valueFilter?.ticketTitle_contains
              }
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
