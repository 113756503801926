export const TYPES = {
  DEFAULT: 0,
  UNSPECIFIED: 1,
  WEB: 2,
  APP: 3,
  SYSTEM: 4,
};

export const REDEMPTION_PLATFORMS = [
  '--',
  'Unspecified',
  'Web',
  'App',
  'System',
];

export const REDEMPTION_PLATFORM = {
  [TYPES.UNSPECIFIED]: REDEMPTION_PLATFORMS[TYPES.UNSPECIFIED],
  [TYPES.WEB]: REDEMPTION_PLATFORMS[TYPES.WEB],
  [TYPES.APP]: REDEMPTION_PLATFORMS[TYPES.APP],
  [TYPES.SYSTEM]: REDEMPTION_PLATFORMS[TYPES.SYSTEM],
};

export const TYPES_USED_PLATFROM = {
  DEFAULT: 0,
  UNSPECIFIED: 1,
  WEB: 2,
  APP: 3,
  SYSTEM: 4,
  WEB_MANUAL: 5,
  APP_MANUAL: 6,
};

export const USED_PLATFORMS_LABEL = [
  '--',
  'Unspecified',
  'Web',
  'App',
  'System',
  'Web (Manual)',
  'App (Manual)',
];

export const USED_PLATFORM = {
  [TYPES_USED_PLATFROM.DEFAULT]:
    USED_PLATFORMS_LABEL[TYPES_USED_PLATFROM.DEFAULT],
  [TYPES_USED_PLATFROM.UNSPECIFIED]:
    USED_PLATFORMS_LABEL[TYPES_USED_PLATFROM.UNSPECIFIED],
  [TYPES_USED_PLATFROM.WEB]: USED_PLATFORMS_LABEL[TYPES_USED_PLATFROM.WEB],
  [TYPES_USED_PLATFROM.APP]: USED_PLATFORMS_LABEL[TYPES_USED_PLATFROM.APP],
  [TYPES_USED_PLATFROM.SYSTEM]:
    USED_PLATFORMS_LABEL[TYPES_USED_PLATFROM.SYSTEM],
  [TYPES_USED_PLATFROM.WEB_MANUAL]:
    USED_PLATFORMS_LABEL[TYPES_USED_PLATFROM.WEB_MANUAL],
  [TYPES_USED_PLATFROM.APP_MANUAL]:
    USED_PLATFORMS_LABEL[TYPES_USED_PLATFROM.APP_MANUAL],
};

export const USED_PLATFORM_OPTIONS = {
  [TYPES_USED_PLATFROM.DEFAULT]:
    USED_PLATFORMS_LABEL[TYPES_USED_PLATFROM.DEFAULT],
  [TYPES_USED_PLATFROM.UNSPECIFIED]:
    USED_PLATFORMS_LABEL[TYPES_USED_PLATFROM.UNSPECIFIED],
  [TYPES_USED_PLATFROM.WEB]: USED_PLATFORMS_LABEL[TYPES_USED_PLATFROM.WEB],
  [TYPES_USED_PLATFROM.APP]: USED_PLATFORMS_LABEL[TYPES_USED_PLATFROM.APP],
  [TYPES_USED_PLATFROM.SYSTEM]:
    USED_PLATFORMS_LABEL[TYPES_USED_PLATFROM.SYSTEM],
};
