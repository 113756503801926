import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {CardContent, Grid, Stack} from '@mui/material';

import {Breadcrumbs, Button, Chip} from '../../../components';
import {
  DataDetail,
  typeRoutes,
} from '../../../types/typeVoucherTransactionHistory';
import {path} from '../../../routes/path';
import MembershipTicketService from '../../../services/MembershipTicket/membership-ticket.service';
import {formatLocalDateFromUTCTime, hasPermission} from '../../../utils';
import {ColorType} from '../../../types/typeChip';
import {
  MEMBERSHIP_TICKET_COLOR,
  MEMBERSHIP_TICKET_LABEL,
  MEMBERSHIP_TICKET_STATUS,
  VOUCHER_TXN_MODAL_INFOMATIONS,
} from '../../../constants/status';
import queryString from 'query-string';
import {ModalInformations as ModalInformationsProps} from '../../../types/typeModals';

// styles
import {
  Typography,
  StyledDivBreadcrumbs,
  Card,
  Divider,
  StyledChip,
  StyledCardContent,
} from './VoucherTransactionHistoryDetail.style';
import {
  REDEMPTION_PLATFORM,
  TYPES,
  TYPES_USED_PLATFROM,
  USED_PLATFORM,
} from '../../../constants/redemptionPlatform';
import TableDetail from '../../../components/Table/TableDetail/TableDetail';
import ModalInformations from '../../../components/Modal/ModalInformations/ModalInformations';
import {images} from '../../../constants/image';

type Rows = {
  label: string;
  value: string;
  icon?: React.ReactNode;
};

function VoucherTransactionHistoryDetail() {
  const navigate = useNavigate();
  const paramsUrl = useParams();
  const locationUrl = useLocation();
  const idDetail = paramsUrl?.id;
  const membershipId = locationUrl?.state?.membershipId;
  const merchantId = locationUrl?.state?.merchantId;
  const ticketDateTimeFormat = 'DD/MM/YYYY HH:mm';

  // states
  const [dataDetail, setDataDetail] = useState<DataDetail>({});
  const [isUsed, setIsUsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    description: '',
  });
  const redemptionPlatformModalInformations: ModalInformationsProps =
    VOUCHER_TXN_MODAL_INFOMATIONS.getvoucher;
  const usagePlatformModalInformations: ModalInformationsProps =
    VOUCHER_TXN_MODAL_INFOMATIONS.usevoucher;

  const renderRedemptionPlatform = (type: number) => {
    if (type === TYPES.DEFAULT) {
      return REDEMPTION_PLATFORM[TYPES.UNSPECIFIED];
    }
    return REDEMPTION_PLATFORM[type];
  };

  const renderUsedPlatform = (row: any) => {
    let usedFrom = row?.usedFrom;
    if (row.pointId !== null) {
      switch (usedFrom) {
        case TYPES_USED_PLATFROM.WEB_MANUAL:
          usedFrom = TYPES_USED_PLATFROM.WEB;
          break;
        case TYPES_USED_PLATFROM.APP_MANUAL:
          usedFrom = TYPES_USED_PLATFROM.APP;
          break;
        case TYPES_USED_PLATFROM.DEFAULT:
          usedFrom = TYPES_USED_PLATFROM.UNSPECIFIED;
          break;
      }
      return USED_PLATFORM[usedFrom];
    }
    return USED_PLATFORM[TYPES_USED_PLATFROM.DEFAULT];
  };

  const getVoucherDetailsB: Rows[] = [
    {
      label: 'Islander member’s AccountID',
      value: dataDetail?.membershipNo,
    },
    {
      label: 'Points redeemed',
      value: dataDetail?.ticketPoint?.toString(),
    },
    {
      label: '‘Get voucher’ platform',
      value: renderRedemptionPlatform(dataDetail?.redeemedFrom),
      icon: (
        <img
          src={images.icon_info}
          alt="Informations"
          style={{display: 'inline-block'}}
          onClick={() =>
            toggleOpenModalInfomations(
              true,
              redemptionPlatformModalInformations.title,
              redemptionPlatformModalInformations.description
            )
          }
        />
      ),
    },
  ];

  const getVoucherDetailsA: Rows[] = [
    {
      label: 'Islander member’s email',
      value: dataDetail?.membershipEmail,
    },

    {
      label: '‘Get voucher’ no.',
      value: dataDetail?.id,
    },
    {
      label: '‘Get voucher’ date and time',
      value:
        formatLocalDateFromUTCTime(
          dataDetail?.createdDateTime,
          ticketDateTimeFormat
        ) || '--',
    },
  ];

  const voucherDetailsA: Rows[] = [
    {
      label: 'Voucher Creation ID',
      value: dataDetail?.ticketNumberId,
    },

    {
      label: 'Voucher title',
      value: dataDetail?.ticketTitle,
    },
  ];

  const voucherDetailsB: Rows[] = [
    {
      label: 'Validity period',
      value: `${formatLocalDateFromUTCTime(
        dataDetail?.ticketExpireStartDateTime,
        ticketDateTimeFormat
      )} - ${formatLocalDateFromUTCTime(
        dataDetail?.ticketExpireEndDateTime,
        ticketDateTimeFormat
      )}`,
    },

    {
      label: 'Voucher description',
      value: dataDetail?.ticketExcerpt,
    },
  ];

  const renderUsageDateTime = row => {
    return row.pointId !== null
      ? formatLocalDateFromUTCTime(row.updatedDateTime, ticketDateTimeFormat)
      : '--';
  };

  const useVoucherDetailsA: Rows[] = [
    {
      label: '‘Use voucher’ date and time',
      value: renderUsageDateTime(dataDetail),
    },

    {
      label: '‘Use voucher’ at IP',
      value: dataDetail?.merchantName,
    },
  ];

  const useVoucherDetailsB: Rows[] = [
    {
      label: '‘Use voucher’ platform',
      value: renderUsedPlatform(dataDetail),
      icon: (
        <img
          src={images.icon_info}
          alt="Informations"
          style={{display: 'inline-block'}}
          onClick={() =>
            toggleOpenModalInfomations(
              true,
              usagePlatformModalInformations.title,
              usagePlatformModalInformations.description
            )
          }
        />
      ),
    },

    {
      label: 'Transaction no.',
      value: dataDetail?.pointId,
    },
  ];

  const getDataDetail = () => {
    setIsLoading(true);
    new MembershipTicketService().getOne(idDetail).then(res => {
      if (res && res.data && res.data.data) {
        setIsLoading(false);
        setDataDetail(res.data.data);
      }
    });
  };

  const renderLink = () => {
    if (membershipId) {
      return `/${
        path.VOUCHER_TRANSACTION_HISTORY
      }?membershipId=${membershipId}${queryString.stringify(
        locationUrl?.state.filterParams
      )}`;
    }
    if (merchantId) {
      return `/${
        path.VOUCHER_TRANSACTION_HISTORY
      }?merchantId=${merchantId}${queryString.stringify(
        locationUrl?.state.filterParams
      )}`;
    }
    return `/${path.VOUCHER_TRANSACTION_HISTORY}?${queryString.stringify(
      locationUrl?.state.filterParams
    )}`;
  };

  const renderStatus = value => {
    if (value) {
      const statusWithPointId =
        value.pointId === null
          ? MEMBERSHIP_TICKET_STATUS.ACTIVE
          : MEMBERSHIP_TICKET_STATUS.USED;
      let renderColor = MEMBERSHIP_TICKET_COLOR[statusWithPointId];
      let label = MEMBERSHIP_TICKET_LABEL[statusWithPointId];
      if (
        (value?.usedFrom === TYPES_USED_PLATFROM.WEB_MANUAL ||
          value?.usedFrom === TYPES_USED_PLATFROM.APP_MANUAL) &&
        statusWithPointId !== MEMBERSHIP_TICKET_STATUS.ACTIVE &&
        value?.status === MEMBERSHIP_TICKET_STATUS.ACTIVE
      ) {
        label = MEMBERSHIP_TICKET_LABEL[MEMBERSHIP_TICKET_STATUS.USED_MANUAL];
        renderColor =
          MEMBERSHIP_TICKET_COLOR[MEMBERSHIP_TICKET_STATUS.USED_MANUAL];
      }
      return <Chip label={label} color={renderColor as ColorType} />;
    }
  };

  const toggleOpenModalInfomations = async (
    isOpen: boolean,
    title: string,
    desc: string
  ) => {
    if (isOpen) {
      return setModalInfo({isOpen: isOpen, title: title, description: desc});
    }
    return setModalInfo({...modalInfo, isOpen: isOpen});
  };

  useEffect(() => {
    getDataDetail();
  }, []);

  useEffect(() => {
    setIsUsed(dataDetail?.pointId !== null);
  }, [dataDetail]);

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />

      <ModalInformations
        onClose={() => toggleOpenModalInfomations(false, '', '')}
        visible={modalInfo.isOpen}
        title={modalInfo.title}
        description={modalInfo.description}
        iconWidth={80}
        iconHeight={80}
      />

      <Typography variant="h3" gutterBottom>
        Voucher Transaction Detail
      </Typography>
      <StyledDivBreadcrumbs>
        <Breadcrumbs
          routes={[
            typeRoutes('Voucher Transaction', renderLink(), false, {}),
            typeRoutes('Detail', null, true),
          ]}
        />
      </StyledDivBreadcrumbs>

      <Divider my={6} />

      <Grid container mb={5}>
        <Grid item xs={12} md={12}>
          <StyledChip>{renderStatus(dataDetail)}</StyledChip>
          <Card
            pl={12}
            pr={12}
            sx={{
              position: 'relative',
              bottom: '16px',
            }}
          >
            <StyledCardContent>
              <Divider my={6} />
              <Grid container justifyContent="space-evenly">
                <Grid item xs={12} md={12}>
                  <TableDetail
                    title="‘Get voucher’ details"
                    rowsA={getVoucherDetailsA}
                    rowsB={getVoucherDetailsB}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TableDetail
                    title="Voucher details"
                    rowsA={voucherDetailsA}
                    rowsB={voucherDetailsB}
                  />
                </Grid>
                {isUsed && (
                  <Grid item xs={12} md={12}>
                    <TableDetail
                      title="‘Use voucher’ details"
                      rowsA={useVoucherDetailsA}
                      rowsB={useVoucherDetailsB}
                    />
                  </Grid>
                )}
              </Grid>
            </StyledCardContent>
          </Card>
        </Grid>
        <Divider my={6} />
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Card px={6} pt={3}>
            <CardContent>
              <Grid container direction="row" justifyContent="center">
                <Stack direction="row" spacing={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    loading={isLoading}
                    onClick={() => {
                      navigate(`/${path.VOUCHER}/${dataDetail?.ticketId}`);
                    }}
                  >
                    Go to Voucher
                  </Button>
                  {isUsed && hasPermission(path.ALL_TRANSACTION, 'read') && (
                    <Button
                      variant="contained"
                      color="primary"
                      loading={isLoading}
                      type="button"
                      onClick={() => {
                        navigate(
                          `/${path.ALL_TRANSACTION}/${dataDetail?.pointId}`
                        );
                      }}
                    >
                      Go to Transaction
                    </Button>
                  )}
                </Stack>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default VoucherTransactionHistoryDetail;
